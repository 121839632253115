import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './page/Login.jsx';
// import Dashboard from './page/Dashboard/Dashboard.jsx';
// import Projects from './pages/Projects/Projects';
// import Categories from './page/Categories/Categories';
// import Products from './page/Projects/Projects';
// import Settings from './pages/Settings';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { LoadingSpin } from './components/AppContainer/LoadingSpin.jsx';
const Dashboard = lazy(() => import("./page/Dashboard/Dashboard.jsx"));
const CategoriesPage = lazy(() => import("./page/Categories/Categories"));
const ProductsPage = lazy(() => import("./page/Products/Products.jsx"));
const ProjectsPage = lazy(() => import("./page/Projects/Projects.jsx"));
const SettingsPage = lazy(() => import("./page/Settings/SettingsPage.js"));

function App() {
  return (
    // <Router>
    //   <Routes>
    //     <Route path="/login" element={<Login />} />

    //     <Route
    //       path="/"
    //       element={
    //         <PrivateRoute>
    //           <div className="flex">
    //             <Sidebar />
    //             <div className="flex-1 p-4">
    //               <Dashboard />
    //             </div>
    //           </div>
    //         </PrivateRoute>
    //       }
    //     />
    //     <Route
    //       path="/dashboard"
    //       element={
    //         <PrivateRoute>
    //           <div className="flex">
    //             <Sidebar />
    //             <div className="flex-1 p-4">
    //               <Dashboard />
    //             </div>
    //           </div>
    //         </PrivateRoute>
    //       }
    //     />
    //     {/* <Route
    //       path="/projects"
    //       element={
    //         <PrivateRoute>
    //           <div className="flex">
    //             <Sidebar />
    //             <div className="flex-1 p-4">
    //               <Projects />
    //             </div>
    //           </div>
    //         </PrivateRoute>
    //       }
    //     /> */}
    //     <Route
    //       path="/categories"
    //       element={
    //         <PrivateRoute>
    //           <div className="flex">
    //             <Sidebar />
    //             <div className="flex-1 p-4">
    //               <Categories />
    //             </div>
    //           </div>
    //         </PrivateRoute>
    //       }
    //     />
    //     <Route
    //       path="/products"
    //       element={
    //         <PrivateRoute>
    //           <div className="flex">
    //             <Sidebar />
    //             <div className="flex-1 p-4">
    //               <Products />
    //             </div>
    //           </div>
    //         </PrivateRoute>
    //       }
    //     />
    //     {/* <Route
    //       path="/settings"
    //       element={
    //         <PrivateRoute>
    //           <div className="flex">
    //             <Sidebar />
    //             <div className="flex-1 p-4">
    //               <Settings />
    //             </div>
    //           </div>
    //         </PrivateRoute>
    //       }
    //     /> */}
    //   </Routes>
    // </Router>


    <div>
    <Routes>
 
      <Route
        path="/login"
        element={<Login />} 
      />
      <Route element={<PrivateRoute />}>
        <Route
          path="/"
          element={
            <Suspense fallback={<LoadingSpin />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="/categories"
          element={
            <Suspense fallback={<LoadingSpin />}>
              <CategoriesPage />
            </Suspense>
          }
        />
        <Route
          path="/products"
          element={
            <Suspense fallback={<LoadingSpin />}>
              <ProductsPage />
            </Suspense>
          }
        />
        <Route
          path="/projects"
          element={
            <Suspense fallback={<LoadingSpin />}>
              <ProjectsPage />
            </Suspense>
          }
        />
        <Route
          path="/settings"
          element={
            <Suspense fallback={<LoadingSpin />}>
              <SettingsPage />
            </Suspense>
          }
        />
       
      
      </Route>
      <Route
          path="*"
          element={
            <Suspense fallback={<LoadingSpin />}>
              "هذه الصفحة غير متوفرة"
            </Suspense>
          }
        />
      {/* <HomePage /> */}
      {/* <ReportPage /> */}
    </Routes>
    </div>
  );
}

export default App;
