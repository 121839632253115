import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AppContainer from '../AppContainer/AppContainer';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // التحقق من وجود التوكن في localStorage

  return token ? children ? <AppContainer> {children} </AppContainer> :
  <AppContainer><Outlet /></AppContainer>
  : <Navigate to="/login" />;
};

export default PrivateRoute;
