import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaLock, FaEnvelope } from 'react-icons/fa';
import { login } from '../api/AuthApi/Index';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   setError('');
 
  //   try {
  //     const response = await fetch('http://localhost:8000/api/login', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ email, password }),
  //     });

  //     const data = await response.json();

  //     if (data.success) {
  //       localStorage.setItem('token', data.token);
  //       navigate('/');
  //     } else {
  //       setError(data.message || 'بيانات الاعتماد غير صحيحة');
  //     }
  //   } catch (err) {
  //     setError('حدث خطأ ما. يرجى المحاولة لاحقاً.');
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    const info = { email, password };
    const result = await login(info);
    if (result.data.success) {
      localStorage.setItem('token', result.data.access_token);
      navigate('/');
    } else {
      setError(result.data.message || 'بيانات الاعتماد غير صحيحة');
    }
  };
  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gray-900 overflow-hidden">
      <div className="absolute inset-0 z-0 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 opacity-75"></div>
      {/* الدوائر العشوائية */}
      <div className="absolute w-72 h-72 bg-[#202225] rounded-full opacity-50 animate-pulse top-10 left-10"></div>
      <div className="absolute w-48 h-48 bg-[#202225] rounded-full opacity-75 animate-ping top-32 right-20"></div>
      <div className="absolute w-32 h-32 bg-[#202225] rounded-full opacity-50 animate-bounce bottom-20 left-32"></div>
      <div className="absolute w-40 h-40 bg-[#202225] rounded-full opacity-60 animate-pulse bottom-10 right-10"></div>
      <div className="absolute w-64 h-64 bg-[#202225] rounded-full opacity-40 animate-bounce top-20 right-40"></div>
      
      <div className="relative z-10 bg-[#202225] p-8 rounded-2xl shadow-lg w-full max-w-md transition-transform duration-500 hover:scale-105 hover:shadow-2xl">
        <h2 className="text-3xl font-extrabold mb-8 text-center text-gray-100 ">أهلاً بك من جديد</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-6">
            <label className="block text-gray-400 text-sm font-semibold mb-2">البريد الإلكتروني</label>
            <div className="flex items-center border border-gray-700 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="px-3 bg-gray-800 flex items-center justify-center">
                <FaEnvelope className="text-gray-500 animate-pulse" />
              </div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 bg-gray-800 text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-600 transition-all duration-300"
                placeholder="أدخل بريدك الإلكتروني"
                required
              />
            </div>
          </div>
          <div className="mb-8">
            <label className="block text-gray-400 text-sm font-semibold mb-2">كلمة المرور</label>
            <div className="flex items-center border border-gray-700 rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="px-3 bg-gray-800 flex items-center justify-center">
                <FaLock className="text-gray-500 animate-pulse" />
              </div>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 bg-gray-800 text-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-600 transition-all duration-300"
                placeholder="أدخل كلمة المرور"
                required
              />
            </div>
          </div>
          {error && <p className="text-red-500 text-center mb-6">{error}</p>}
          <button
            type="submit"
            className="w-full bg-gray-700 text-gray-200 font-semibold p-3 rounded-lg shadow-md hover:shadow-lg hover:bg-gray-600 transition-all duration-500"
          >
            تسجيل الدخول
          </button>
        </form>
       
      </div>
    </div>
  );
};

export default Login;
