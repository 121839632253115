import { Layout, Menu, Drawer, Button } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // إضافة useNavigate
import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo.png';
import { HomeOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { FaTags } from "react-icons/fa6";
import { FaBox, FaCog, FaProjectDiagram } from 'react-icons/fa';

const { Sider } = Layout;

const Sidebar = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const navigate = useNavigate(); // استخدام useNavigate
  const [current, setCurrent] = useState('/');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const handleLogout = () => {
    // مسح Local Storage
    localStorage.clear();

    // تحويل المستخدم إلى صفحة تسجيل الدخول
    navigate('/login');
  };

  return (
    <>
      {/* Navbar for mobile */}
      <div className="md:hidden fixed  top-0 left-0 w-full bg-[#001529] text-white z-50 flex justify-between items-center p-4 shadow-md">
        <img className="w-8 h-8" src={logo} alt="Logo" />
        <Button icon={<MenuOutlined />} onClick={toggleDrawer} />
      </div>

      {/* Sidebar for desktop */}
      <div className='hidden   h-screen bg-[#001529] md:block'>
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
          theme="dark"
          width={250}
        >
          <div className="demo-logo-vertical" />
          <div className='h-16 flex text-xl flex-col items-center justify-center mb-5 mt-6'>
            {
              collapsed ?
                <img className='w-10 h-20 mt-4 hover:cursor-pointer animate__animated animate__fadeInDown' onClick={() => setCollapsed(!collapsed)} src={logo} />
                : <img className='w-10 h-20 mt-10 hover:cursor-pointer animate__animated animate__fadeInDown' onClick={() => setCollapsed(!collapsed)} src={logo} />
            }
            {
              !collapsed ?
                <p className='text-m p-1 animate__animated animate__fadeInDown text-white'>لوحة التحكم</p>
                : null
            }
          </div>
          <Menu
            defaultSelectedKeys={['/']}
            mode="inline"
            className='text-l  font-bold mt-10'
            selectedKeys={[current]}
            theme="dark"
            items={[
              {
                key: '/',
                label: <Link to={'/'}>الرئيسية</Link>,
                icon: <HomeOutlined style={{ fontSize: '20px' }} />,
              },
              {
                key: '/projects',
                label: <Link to={'/projects'}> المشاريع </Link>,
                icon: <FaProjectDiagram style={{ fontSize: '20px' }} />,
              },
              {
                key: '/products',
                label: <Link to={'/products'}>المواد</Link>,
                icon: <FaBox style={{ fontSize: '20px' }} />,
              },
              {
                key: '/categories',
                label: <Link to={'/categories'}>التصنيفات</Link>,
                icon: <FaTags style={{ fontSize: '20px' }} />,
              },
              
              {
                key: '/settings',
                label: <Link to={'/settings'}>الاعدادات</Link>,
                icon: <FaCog style={{ fontSize: '20px' }} />,
              },
              
            ]}
          />
          <Button
            type="button"
            icon={<LogoutOutlined style={{ fontSize: '20px', color: 'white' }} />}
            onClick={handleLogout}
            className="w-full text-left px-4   text-white"
          >
            تسجيل الخروج
          </Button>
        </Sider>
      </div>

      {/* Drawer for mobile */}
      <Drawer
        title="لوحة التحكم"
        placement="right"
        closable={true}
        onClose={toggleDrawer}
        visible={isDrawerVisible}
        bodyStyle={{ backgroundColor: '#001529', color: 'white', paddingBottom: '60px' }} // لإفساح المجال للزر
      >
        <Menu
          defaultSelectedKeys={['/']}
          mode="inline"
          selectedKeys={[current]}
          theme="dark"
          items={[
            {
              key: '/',
              label: <Link to={'/'}>الرئيسية</Link>,
              icon: <HomeOutlined style={{ fontSize: '20px' }} />,
            },
            {
              key: '/projects',
              label: <Link to={'/projects'}>المشاريع</Link>,
              icon: <FaProjectDiagram style={{ fontSize: '20px' }} />,
            },
            {
              key: '/products',
              label: <Link to={'/products'}>المواد</Link>,
              icon: <FaBox style={{ fontSize: '20px' }} />,
            },
            {
              key: '/categories',
              label: <Link to={'/categories'}>التصنيفات</Link>,
              icon: <FaTags style={{ fontSize: '20px' }} />,
            },
            {
              key: '/settings',
              label: <Link to={'/settings'}> الاعدادات </Link>,
              icon: <FaCog style={{ fontSize: '20px' }} />,
            },
          ]}
        />
        <Button
          type="text"
          icon={<LogoutOutlined style={{ fontSize: '20px', color: 'white' }} />}
          onClick={handleLogout}
          className="w-full text-left text-white"
          style={{ position: 'absolute', bottom: '20px' }} // يجعل الزر قريبًا من أسفل الشاشة في Drawer
        >
          تسجيل الخروج
        </Button>
      </Drawer>
    </>
  );
};

export default Sidebar;
