import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar'
  import { Layout, Menu, Button, theme } from 'antd';
import { Footer, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
// import { Navbar } from './navbar';
  const { Content } = Layout;
  
const AppContainer = ({children}) => {
    const {
      token: { colorBgContainer },
    } = theme.useToken();
    const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className='h-screen'>
    <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
   <Layout>
     {/* <Header
       style={{
         padding: 0,
         background: `rgb(119, 109, 187)`,
       }}
     >
       <Button
         type="text"
         icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
         onClick={() => setCollapsed(!collapsed)}
         style={{
           fontSize: '16px',
           width: 64,
           height: 64,
         }}
       />
       
     </Header> */}
      {/* <Navbar
         collapsed={collapsed}
         setCollapsed={setCollapsed}
       /> */}
     <Content
       style={{
         margin: '0px 0px',
         // padding: 10,
         minHeight: 280,
       }}
       className='md:px-12 md:py-10 p2'
     >
      
       {children}
     </Content>
   </Layout>
 </Layout>


  )
}

export default AppContainer